@import url(./assets/scss/_animation.scss);
* {
  scrollbar-width: thin;
}
.skiptranslate {
  iframe {
    display: none;
  }
}
#hs-overlay-switcher {
  #theme-toggler {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      width: 16rem;
      height: 5rem;
      position: relative;
      display: block;
      background: #ebebeb;
      border-radius: 5rem;
      box-shadow:
        inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 2px -3px 5px 0 rgba(255, 255, 255, 0.4);
      cursor: pointer;
      transition: 0.3s;
    }
    label:after {
      content: "";
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: 10px;
      left: 10px;
      background: linear-gradient(180deg, #ffcc89, #d8860b);
      border-radius: 4rem;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }
    input {
      width: 0;
      height: 0;
      visibility: hidden;
    }
    input:checked + label {
      background: #242424;
    }
    input:checked + label:after {
      left: 15.2rem;
      transform: translateX(-100%);
      background: linear-gradient(180deg, #777, #3a3a3a);
    }
    label:active:after {
      width: 5rem;
    }
    label svg {
      position: absolute;
      width: 3rem;
      top: 1.1rem;
      z-index: 100;
    }
    label svg.sun {
      left: 1.1rem;
      fill: #fff;
      transition: 0.3s;
    }
    label svg.moon {
      left: 11.7rem;
      fill: #7e7e7e;
      transition: 0.3s;
    }
    input:checked + label svg.sun {
      fill: #7e7e7e;
    }
    input:checked + label svg.moon {
      fill: #fff;
    }
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
.runner {
  height: 4px;
  width: 200px;
  background: #ffa800;
  position: absolute;
  animation-name: run;
  animation: linear infinite;
  animation-duration: 2s;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 2s;
}
.icon {
  font-family:
    source sans pro,
    sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 12px;
  font-size: 12px;
  width: 45px;
  height: 45px;
  // line-height: 45px;
  transform: translateY(2px);
}
.card {
  --tw-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: #64748b;
  display: flex;
  min-width: 1px;
  overflow-wrap: break-word;
  position: relative;
}
:is(.dark .card) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  background-color: rgb(8 8 7 / 1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  color: #a3adc2;
}
@-webkit-keyframes run {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
@keyframes run {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
body {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  .ellipse {
    position: absolute;
    width: 167px;
    height: 167px;
    background: rgba(241, 241, 241, 0.2);
    filter: blur(100px);
    z-index: -1;

    &.left-top {
      top: -63px;
      left: -69px;
    }
    &.right-middle {
      top: 229px;
      right: 10px;
    }
    &.left-bottom {
      top: 521px;
      left: -69px;
    }
  }
  .auth-form-button {
    /* Rectangle 4151 */
    width: 300px;
    height: 40px;
    background: #ffa800;
    border-radius: 5px;
  }
  .default-button {
    height: 40px;
    background: #ffa800;
    border-radius: 5px;
  }
  .social-btn {
    background: rgba(255, 168, 0, 0.1);
    border-radius: 5px;
    color: #161514;
    height: 40px;
  }
  $breakpoint-tablet: 768px;
  @media (max-width: $breakpoint-tablet) {
    .auth-input-label,
    .form-error {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: -0.30000001192092896px;
    }
  }

  .react-select__menu {
    position: absolute;
    z-index: 509999999;
    height: 300px;
  }
}
.text-primary {
  color: #ffa800;
}
:is(.dark .dark\:bg-jacarta-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 21 20 / var(--tw-bg-opacity));
}
.offcanvas {
  -webkit-backdrop-filter: saturate(180%) blur(30px);
  backdrop-filter: saturate(180%) blur(30px);
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: all !important;
  // overflow: scroll;
  -webkit-overflow-scrolling: touch;
  // z-index: 9999 !important;
  border: 0 !important;
}
.dark {
  .offcanvas {
    background-color: rgb(22, 21, 20, 0.8);
    -webkit-backdrop-filter: saturate(180%) blur(30px) !important;
    backdrop-filter: saturate(180%) blur(30px) !important;
  }
  .runner {
    height: 2px;
  }
  body {
    background-color: #161514;
    .about {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #fff;
      }
    }
    .ellipse {
      background: rgba(238, 43, 0, 0.2);
    }
    .auth-form-button,
    .default-button {
      color: #161514;
    }
    .social-btn {
      color: #fff;
    }
    .react-select__control {
      border-radius: 5px;
      background: rgba(255, 168, 0, 0.1);
      border: unset;
      &.react-select__control--is-focused {
        border: 1px #ffa800 solid !important;
      }
    }
    .react-select__option {
      &.react-select__option--is-focused {
        background-color: #ffaa0067;
      }
      &.react-select__option--is-selected {
        background-color: #ffaa00;
      }
    }
    .react-select__single-value {
      color: #fff;
    }
    .react-select__input-container {
      color: #d1d1d1;
    }
  }
}

.is-scrollbar-hidden {
  scrollbar-width: none;
}

.is-scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

@supports selector(::-webkit-scrollbar) {
  .scrollbar-sm::-webkit-scrollbar {
    height: 0.375rem;
    width: 0.375rem;
  }

  .scrollbar-sm:focus-within::-webkit-scrollbar-thumb,
  .scrollbar-sm:hover::-webkit-scrollbar-thumb {
    background-color: rgba(225, 219, 203, 0.8);
    border-radius: 9999px;
  }

  :is(.dark .scrollbar-sm:focus-within)::-webkit-scrollbar-thumb,
  :is(.dark .scrollbar-sm:hover)::-webkit-scrollbar-thumb {
    background-color: #8a7a5c;
  }

  .scrollbar-sm::-webkit-scrollbar-thumb:hover {
    background-color: #b8b194;
  }

  :is(.dark .scrollbar-sm)::-webkit-scrollbar-thumb:hover {
    background-color: #9b8e69;
  }
}
.slick-slide {
  margin-left: 10px !important;
}
.separate .lazy-load-image-background {
  margin-right: 12px;
  border-radius: 180px;
}
.user-info {
  .lazy-load-image-background {
    border-radius: 100%;
  }
}
