@keyframes floating {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 99999;
  svg {
    animation: floating 1s cubic-bezier(0.4, -0.35, 0.01, 1.08) infinite
      alternate;
  }
}
